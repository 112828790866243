import React from "react";
import {Link} from "react-router-dom";

import "./NavBar.css";
import json from "./../media.json";

const NavBar = () => {
  const selectedWorks = [];

  json.forEach(d => {
    if (d.show) selectedWorks.push({'link': d.link, 'title': d.title})
  })

  return (
      <div className="text-sm left-0 relative">
          <Link to="/" className="flex cursor-pointer p-1 text-[rgb(0,0,255)] " key='link-name'>
              Kii Kang | 康원기
          </Link>
          <div className="p-1">
            <br/>
            <Link to="/" className="flex text-[rgb(0,0,255)]" key='link-home'>
              Home
            </Link>
            <Link to="/info/" className="flex text-[rgb(0,0,255)]" key='link-info'>
              Info
            </Link>
            <div className="u-inlineBlock" key='projects-container'>
              <br />
              <p> Selected Works </p>
              {
                selectedWorks.map(d => (
                    <Link to={ '/' + d.link} className='text-[rgb(0,0,255)] hover:text-white' > { d.title }<br/> </Link>
                ))
              }
              <br />
              <p> Writings </p>
              <Link to={'/availabler'} className='text-[rgb(0,0,255)] hover:text-white'> Ambiguous Surfaces, Inquiring Bodies </Link>
            </div>
          </div>
      </div>
  );
};

export default NavBar;
