import React, {useEffect} from "react";
import './Projects.css'
const P_BinauralKoreas = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div className="Project-container-c">
      <div className="Project-container">
        <div className="Project-header">
          Year: 2021<br/>
          Domain: soundscape composition, geopolitics, sound studies
        </div>
        <div className="u-img-container-full">
          <img src ={process.env.PUBLIC_URL + '/media/binaural_koreas_1.jpeg'} title='Propaganda Broadcast Loudspeakers at the Southern Border of DMZ. source: DMZ Museum'/>
          <p className='u-caption u-textRight'>
            Propaganda Broadcast Loudspeakers at the Southern Border of DMZ. source: DMZ Museum.
          </p>
        </div>

          <p>
          <b>Binaural Koreas</b> is an audiovisual experiment on semi-fictional soundscape composition representing the political identities, conflicts, and reconciliation of the two Koreas. While examining the perceptual dimensions of peace in Korean peninsula, Ahn proposes co-construction of the ‘democratic we’ as opposed to the nationalist restoration of the original Korea.<sup>1</sup> The Demilitarized Zone (DMZ) between South and North Korea has been a battleground of sonic warfare, propaganda speakers blasting at each other, expressing the decades-long hatred, grief, distrust, and manipulation. How can this split be reconvened through sound? Seventy years of the divide between South and North Korea, are represented through the binaural split, the two speakers asserting their own territories yet resonating within a single stereophonic <i>place</i>.
        </p>
          <div className="u-img-container-center">
              <img src ={process.env.PUBLIC_URL + '/media/binaural_koreas_2.jpeg'} title='Propaganda Broadcast Loudspeakers at the Southern Border of DMZ. source: DMZ Museum' alt=""/>
              <p className='u-caption u-textRight'>
                  South Korean Propaganda Leaflet(trans. "Come in to the arms of Free Korea where freedom and happiness and abundance overflows!"). source: BBC Korea(back), DMZ Museum(front).
              </p>
          </div>
          <p>
          Among the wide range of propaganda operations targeted at each other, loudspeaker broadcasting, or direct broadcasting, involves enormous arrays of loudspeakers installed on both sides of the border. This sonic psyop started in 1967 and still operating whenever the political tension heightens, and it is known that each side operates about 40 units of speakers along the border. They are multiplied so that they are powerful enough to travel miles far, in order to permeate beyond border and even towards the civilian villages.
        </p><p>
          The sound is accompanied by the moving image of DMZ, a <i>no man’s land</i> where the natural biomes are thriving being left undisturbed for decades, yet hiding thousands of landmines and bodies buried underneath.
      </p><p>
          In the meantime, the sounds that are used in the broadcast, which are used as material for the soundscape composition, reveal in the most vulnerable way the state of affairs between the two Koreas. Bleiker observes the identities of two Koreas are largely constructed in negative terms to their opponents, South in terms of anti-communist, and North anti-democracy, anti-US sentiment.<sup>2</sup> This identity of the negativity, in the case of the South, has been merging with the neo-capitalist pop culture exemplified by K-pop songs in the past decade. The sounds represent the double reflection presented through the negative image of self-description of their own culture and ideologies. Then the placeness of DMZ is the representative of this stereophonic space, of two channels, North and South saturating a single sphere of perception.
        </p>
        <div className="u-media">
          <iframe src="https://player.vimeo.com/video/691599629?h=0cb1776f0e"
                  allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
        </div>
        {/*<p>*/}
        {/*  The audio materials include recordings of a shortwave radio, K-pop songs, and old folk songs that my grandfather used to hum. It is fictional because first, people who had physically been in the DMZ are extremely limited — soldiers on demining operation, or maybe the defectors who were brave enough to escape the vigilant guards working 24/7 — and second the information on what, who, and how are the sounds get selected for the broadcast is very limited due to security reasons. They are treated as something in between environmental recording, music, and radiowaves. It then ends with the composer Yun Isang’s piece Piri from 1971, Yun aspired to the reunification of South and North Korea, but got kidnapped by the South Korean secret service, and got tortured to confess to alleged acts of espionage.*/}
        {/*</p>*/}
        {/*  I would like to express my special thanks to <a href="https://music.fas.harvard.edu/people/faculty/landon-morrison/" target="_blank" rel="nonreferrer">Dr. Landon Morrison</a> for*/}
        {/*  his guidance in developing this project.*/}
        {/*  <br /><br />*/}
          <hr />
        <div className="text-xs">
          <p>1 Ahn, Ilsup. “Deconstructing the DMZ: Derrida, Levinas and the Phenomenology of Peace.” Cooperation and Conflict 45, no. 2 (June 1, 2010): 205–23. <a href="https://doi.org/10.1177/0010836710370249" className="u-link-bib" target="_blank">link</a>.
            <br />
            2 Bleiker, Roland. “Identity and Security in Korea.” The Pacific Review 14, no. 1 (January 2001): 121–48. <a href="https://doi.org/10.1080/09512740010018589" className="u-link-bib" target="_blank">link</a>.
          </p>
        </div>
          <p>
          </p>
      </div>
        </div>
    )
}

export default P_BinauralKoreas
