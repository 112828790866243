import React, {useEffect} from "react";
import './Projects.css'
const P_Chimera = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div className="Project-container-c">
        <div className="Project-container">
            <div className="Project-header">
                Year: 2021-2022<br/>
                Domain: web development, social computing<br/>
                Collaborators: Kwan Queenie Li, Wiehan Jiang, Charles Wu
            </div>
            <div className="u-img-container-full">
                <video autoPlay loop muted>]
                    <source src={ process.env.PUBLIC_URL + '/media/chimera_place_0.mp4' }
                    type="video/mp4" />
                </video>
            </div>
            <p>
                <b>Chimera.place</b> is an experiment on alternative means of “reading together.” The pilot circulation took place on the summer of 2021, and further developed into a web environment facilitating the reading circulation on the summer of 2022.
            </p>
            <p>
                Biology borrowed the term chimera in Greek mythology to describe a single organism composed of cells more than one distinct genotype. Such phenomenon in fact frequently occurs in the nature. In some cases, one of the twins in the mother's womb might be absorbed by the other, who'll carry on living with two sets of DNA and become a human chimera. Natural chimera might be hard to identify. Some people often find their true identities in the most bizarre circumstances. In the wildest imagination, a chimera is much more identifiable: a fear-provoking fire-breathing monster made of lion, goat and
                snakes. Perhaps it is also the symbol of omnipotence, which can be shown in the Chinese myth of dragon, a chimera itself, that justifies the divine right of emperors. Or, we are in fact chimeras, as our body is the result of endosymbiosis in a micro scale, and in a broader scale our culture is the ongoing cross-contamination of differences. Boundaries have been diminishing (hopefully). Be reminded that, before the mass printings, knowledge circulated in the form of manuscript that went through different hands.
            </p>
            <div className="u-img-container-center">
                <img src={ process.env.PUBLIC_URL + '/media/chimera_place_2.jpeg' }/>
            </div>
            <p>
                Modifications, interpretations, and annotations eclipse the pristine notion of authorship. In this reading experiment, we are collectively making visible the becoming of a chimera, or the knowledge exchange and building in the distinguishable parts we add on to each other's text.
            </p>
            {/*<div className="u-img-container-full">*/}
            {/*    <img src ={process.env.PUBLIC_URL + '/media/chimera_place_0.jpeg'} title='chimera.place - Home'/>*/}
            {/*</div>*/}
        </div>
        </div>
    )
}

export default P_Chimera
