import React from "react";
import './NavBar.css';

const CollabBar = () => {
  return (
    <div className="relative bottom-0 left-0 text-[0.65rem] text-gray-600 p-1 leading-snug" key="NavBar-bottom" rel="noreferrer">
      <div key="NavBar-collab">
          <p className="">Collaborators</p>
      <a href="https://ivettakang.com/" target="_blank" rel="noreferrer">
        Ivetta Kang</a> (2022- ) <br />
      <a href="https://cristobalgarciabelmont.com/" target="_blank" rel="noreferrer">
          Cristóbal Garcia Belmont </a> (2023) <br />
        Aidan Flynn (2022)<br />
      <a href="https://angiedoor.com/" target="_blank">Angie Door</a> (2022)<br />
      Alice Jia Li Song (2022)<br />
      <a href="https://kwanq.cargo.site/" target="_blank" rel="noreferrer">
        Kwan Queenie Li</a> (2021-2022)<br />
      Weihan Jiang (2021)
      <br />
      <a href="https://www.meganprakash.com/" target="_blank" rel="noreferrer">
        Megan Prakash</a> (2021)
      <br />
      Wuyahuang Li (2021)
      </div><br />
      <div>
        ---<br/><br/>
        Updated Jun, 2024
      </div>
    </div>
  )
}

export default CollabBar;
