// import logo from './logo.svg';
import './App.css';
import {
    BrowserRouter as Router, Route, Routes,
} from "react-router-dom";
import Feed from "./pages/Feed";
import Info from "./pages/Info";
import P_BinauralKoreas from "./pages/p_BinauralKoreas";
import P_EmbodyingANarrative from "./pages/p_EmbodyingANarrative";
import P_Availabler from "./pages/p_Availabler";
import NavBar from "./modules/NavBar";
import CollabBar from "./modules/CollabBar";
import P_EOW from "./pages/p_EOW";
import P_Chimera from "./pages/p_Chimera";
import P_Thesis from "./pages/p_Thesis";

function App() {
  return (
      <Router>
          <div className="border-r-black w-[100vw]">
              <div className="float-left flex flex-col justify-between border-r-black border-[0.5px] w-[250px] h-[100vh]">
                <NavBar/><CollabBar/>
              </div>

          <Routes>
              <Route path="/"
                     element={<Feed/>}
              />
              <Route path="/info"
                     element={<Info/>}
              />
              <Route path="/binaural-koreas"
                     element={<P_BinauralKoreas/>}
              />
              <Route path="/embodying-a-narrative"
                     element={<P_EmbodyingANarrative/>}
              />
              <Route path="/availabler"
                     element={<P_Availabler/>}
              />
              <Route path="/experience-oriented-wayfinding"
                     element={<P_EOW/>}
              />
              <Route path="/chimera-place"
                     element={<P_Chimera/>}
              />
              <Route path="/attuning-to-hyperobjects"e
                     element={<P_Thesis/>}
              />

          </Routes>
          </div>
      </Router>
      // <div className="App-container">
      //     <Router>
      //     <NavBar />
      //     <CollabBar path="/" />
      //       <div className="App-contents">
      //           <Feed path="/" />
      //           <Info path="/info/" />
      //           <P_BinauralKoreas path="/binaural-koreas/" />
      //           <P_EmbodyingANarrative path="/embodying-a-narrative/" />
      //           <P_Availabler path="/availabler" />
      //           <NotFound default />
      //       </div>
      //     </Router>
      // </div>
  );
}

export default App;
