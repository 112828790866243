import React from "react"

import "../utilities.css";

const Info = () => {
  return (
    <>
      <div className="Project-container-c">
        <div className="Project-container">
          Kii (Wonki) Kang is an architect, programmer, and “hacker” based in Brooklyn, New York. He is interested in using computation as a tool to blur binaries, reassemble temporalities, and embody semiotics. He holds a dual masters in Architecture Studies and Computer Science from MIT.
          <br/><br/>
          <a className="u-link" href="mailto: wonki.kang.official@gmail.com">
            wonki.kang.official [at] gmail [dot] com
          </a>
        </div>
        {/*<div className="Info-text">*/}
        {/*  <p className="Info-title">EDUCATION:</p>*/}
        {/*  Master of Science in <br />*/}
        {/*  Architectural Studies & Computer Science,<br />*/}
        {/*  Massachusetts Institute of Technology<br />*/}
        {/*  2019-2022<br /><br />*/}
        {/*  Bachelor of Architecture,<br />*/}
        {/*  Seoul National University<br />*/}
        {/*  2011-2016*/}
        {/*</div>*/}

      </div>
    </>
  )
}

export default Info;
