import React from "react";
import LazyLoad from "react-lazy-load";
import json from "./../media.json";
import './Feed.css'
import {useNavigate} from "react-router-dom";

const Feed = () => {
  function getFileExtension(filename) {
    return filename.split('.').pop();
  }

  function bringToFront(e) {
    e.target.zIndex = 999;
  }

  const navigate = useNavigate();

  return (
      <div className="Project-container-c">

      <div className="w-[80%] float-left flex flex-wrap u-column-right">
      {
        json.map(d => {
          if (getFileExtension(d.thumbnail) === "mp4"){
            return (
              <div className="Feed-link-container"
                   style = {{
                     width: d.imgSize === "LL" ? '50vw' :
                       d.imgSize === "L" ? '40vw' :
                         d.imgSize === "ML" ? '30vw' :
                           d.imgSize === "M" ? '20vw' : '15vw',
                     maxWidth: '600px',
                     // minWidth: d.imgSize === "S" ? '240px':'400px',
                     // alignSelf: i % 2 === 0 ? '' : 'end',
                     // marginLeft: -Math.random()*200+'px',
                   }}>
                {d.url === "page" ?
                    (
                        <div className="Feed-img-container"
                        onClick={() => navigate("/" + d.link)}
                        style={{cursor: "pointer"}}>
                          <video autoPlay loop muted>]
                            <source src={process.env.PUBLIC_URL + '/media/' + d.thumbnail}
                                    type="video/mp4"/>
                          </video>
                        </div>
                    )
                    : (
                        <a href={d.url}
                           target="_blank" rel="noreferrer"
                           className="Feed-img-container">
                          <LazyLoad className="Feed-img-container">
                            <video autoPlay loop muted>]
                              <source src={process.env.PUBLIC_URL + '/media/' + d.thumbnail}
                                      type="video/mp4"/>
                            </video>
                          </LazyLoad>
                        </a>
                    )
                }
              </div>
            )
          } else {
            return (
              <div className="Feed-link-container"
                   onMouseEnter={ bringToFront }
                    style = {{
                      width: d.imgSize === "LL" ? '40vw' :
                        d.imgSize === "L" ? '30vw' :
                          d.imgSize === "ML" ? '25vw' :
                            d.imgSize === "M" ? '20vw' : '15vw',
                      maxWidth: '800px',
                      minWidth: d.imgSize === "S" ? '240px':'300px',
                      // alignSelf: i % 6 === 0 ? 'start' :
                      //            i % 3 === 1 ? 'center' :
                      //            i % 6 === 2 ? 'end' :
                      //            i % 6 === 3 ? 'center' :
                      //            i % 6 === 4 ? 'start' :'center',
                      // marginLeft: window.innerWidth < 600 ? 'var(--l)' : -Math.random()*100+'px',
                      // marginTop: window.innerWidth < 600 ? 'var(--l)' : -Math.random()*100+'px',
                    }}>
                {d.url === "page" ?
                    (
                        <div className="Feed-img-container"
                             onClick={() => navigate("/" + d.link)}
                             style={{cursor: "pointer"}}>
                          <img src={ process.env.PUBLIC_URL + '/media/' + d.thumbnail }
                               title={ d.caption ? d.caption : d.title + ', ' + d.year }
                               loading='lazy' alt=''/>
                        </div>
                    ):(
                    <a href={ d.url }
                   target="_blank"
                   className="Feed-img-container"
                    style={{cursor: d.url ? "pointer": "default"}}>
                  <LazyLoad className="Feed-img-loader">
                    <img src={ process.env.PUBLIC_URL + '/media/' + d.thumbnail }
                    title={ d.caption ? d.caption : d.title + ', ' + d.year }
                    loading='lazy' alt=''/>
                  </LazyLoad>
                </a>
                    )}
              </div>
            )
          }
        })
      }
    </div>
      </div>
  );
}

export default Feed;
