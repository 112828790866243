import React from "react";
import './Projects.css'

const P_EmbodyingANarrative = () => {
    return (
        <div className="Project-container-c">
      <div className="Project-container">
        <div className="Project-header">
          Year: 2021<br/>
          Domain: human-computer interaction, virtual reality, immersive journalism, photogrammetry<br/>
          Collaborators: Megan Prakash, Kwan Queenie Li, Wuyahuang Li<br/>
        </div>
        <div className="u-img-container-center ">
          <img className="" src ={process.env.PUBLIC_URL + '/media/embodying_a_narrative_1.jpeg'} />
        </div>
        <p>
            <b>Embodying a Narrative: Spatializing Abstract Narrative Themes for Forensic Exploration</b> introduces a mixed reality interface where the audience can explore many facets of an event rather than having a single thread of narrative. Mixed-ness of the reality is crucial here, as it involves bodies; curious, exploring, and undisciplined bodies.
        </p>
        <div className="u-img-container u-no-shadow">
          <img src ={process.env.PUBLIC_URL + '/media/embodying_a_narrative_3.jpeg'} />
        </div>
          <p>
              We present the stories of those who participated in the Occupy City Hall, the June 2020 sit-in protest in New York City, NY. The experience starts in the middle of a living room. Based on certain interactions, the space transforms into a hybrid reality enabled by the ambient sound and the 360 video of the site.

          </p>
        <div className="u-media">
          <iframe src="https://www.youtube.com/embed/a8z4mASDpU4"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowFullScreen></iframe>
        </div>
      <p>
          We are grateful to <a href="https://foxharrell.com/" target="_blank" rel="noreferrer">Prof. D. Fox Harrell</a> and <a href="https://www.cagrizaman.com/" target="_blank" rel="noreferrer">Dr. Cagri Hakan Zaman</a> of MIT for their class, which provided the education and opportunity to create this project.
      </p>
        <br/>
<div className="u-grey">
  <hr/>
  Published in CHI 2021:
  <a className="u-link" href="https://dl.acm.org/doi/10.1145/3411763.3451593" target="_blank" rel="noreferrer"> link</a> <br/>
  Featured in MIT News:
  <a className="u-link" href="https://architecture.mit.edu/news/how-mit-students-are-transforming-art-narrative" rel="noreferrer" target="_blank"> link</a>
    <br/><br/>
<p>
    This project contains copyrighted material, the use of which may not have been specifically authorized by the copyright owner. This material is available in an effort to contribute to human-computer interaction and new media research and to participate in education around virtuality and sociology. The material contained in this website is distributed without profit for research and educational purposes. Only small portions of the original work are being used and those could not be used easily to duplicate the original work.
<br/><br/>
    This should constitute a ‘fair use’ of any such copyrighted material (referenced and provided for in section 107 of the US Copyright Law).
    <br/><br/>
    If you wish to use any copyrighted material from this site for purposes of your own that go beyond ‘fair use’, you must obtain expressed permission from the copyright owner.
</p>
</div>
      </div>
        </div>
    )
}

export default P_EmbodyingANarrative
