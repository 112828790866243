import React from "react";
import './Projects.css'
const P_EOW = () => {
    return (
        <div className="Project-container-c">
      <div className="Project-container">
        <div className="Project-header">
          Year: 2018<br/>
          Domain: user experience design, urbanism
        </div>
      <p>
          <b>Experience Oriented Wayfinding</b> was initiated in the context of D2D(door to door)
          platform organized by <a href="https://www.naverlabs.com/" target="_blank">NaverLabs</a>, which envisions thorough
          mobility service covering every single step of an individual
          journey. Focusing mainly on the pedestrian experience, I
          researched how to envision a new type of navigation that is
          intuitive and personalized.
      </p><p>
        The goal of the whole project was to strategize our perception
          of a space and to make a machine to effectively augment and
          sync to such innate perceptual capability. Rather than turn-by-turn guide that is prevalent in the current navigation
          systems, I envisioned a new strategy: an experience-oriented
          wayfinding. In developing the system, I was able to positively adopt urban design theories,
          especially Kevin Lynch’s idea on the perceptual form of urban
          environments.
      </p>
      <div className="u-media">
          <iframe src="https://www.youtube.com/embed/DJPWFTm1LxE?si=L9PdFiNhCEanKyyG"
                  allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
      </div>
      <hr/>
      <div className="u-grey">
        The project is patented in Korea and in the U.S. <a href="https://image-ppubs.uspto.gov/dirsearch-public/print/downloadPdf/11280631" target="_blank">link</a>
      </div>

      </div>
        </div>
    )
}

export default P_EOW
